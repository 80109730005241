import $api from "../http";


export default class AvailableService {
    static async getAvailableDays(date: string) {
        const res = await $api.get(`client/v1/slots/available/2/?day=${date}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
}