import { createAsyncThunk } from '@reduxjs/toolkit';
import CertificateService from '../../services/CertificatesService';


export const requestGetCertificates = createAsyncThunk('/client/v1/documents/', async () => {
    const response = await CertificateService.getCertificates();
    return response;
})

export const requestGetCertificate = createAsyncThunk('/client/v1/documents/document', async (certId: number | string) => {
    const response = await CertificateService.getCertificate(certId);
    return response;
})