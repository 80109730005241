import { configureStore } from '@reduxjs/toolkit';
import type {TypedUseSelectorHook} from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import auth from './auth/slice';
import chats from './chats/slice';
import profile from './profile/slice';
import packages from './packages/slice';
import payments from './payments/slice';
import myServices from './my-services/slice';
import certificates from './certificates/slice';
import available_times from './availableDay/slice';


export const store = configureStore({
    reducer: {
        auth,
        chats,
        profile,
        packages,
        payments,
        myServices,
        certificates,
        available_times,
    },
})

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
