import './assets/css/App.scss';

// Router
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Redux
import { ReduxProvider } from './redux/provider';

// Telegram
import { useWebApp } from '@vkruglikov/react-telegram-web-app';

// Layouts
import { MainLayout } from './layouts/Layouts';

// Pages
import Pay from './pages/pay/Pay';
import Home from './pages/home/Home';
import Chat from './pages/chat/Chat';
import Welcome from './pages/welcome/Welcome';
import Profile from './pages/profile/Profile';
import Support from './pages/support/Support';
import Package from './pages/package/Package';
import Documents from './pages/documents/Documents';
import MySessions from './pages/my_sessions/MySessions';
import ChooseDate from './pages/choose_date/ChooseDate';


function App() {
  // Setup TG Color
  const WebApp = useWebApp();

  WebApp.headerColor = '#e6f2c5';

  // Full Screen TG
  if (!WebApp.isExpanded) {
    WebApp.expand();
  }

  // Enable Closing Confirm
  WebApp.enableClosingConfirmation();

  return (
    <ReduxProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path='/home' element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path='my-sessions' element={<MySessions />} />
            <Route path='profile' element={<Profile />} />
            <Route path='documents' element={<Documents />} />
            <Route path='support' element={<Support />} />
            <Route path='package/:packageId' element={<Package />} />
            <Route path='payment-page' element={<Pay />} />
            <Route path='chat/:chatId/:type' element={<Chat />} />
          </Route>
          <Route path='/home/choose-date/:serviceId' element={<ChooseDate />} />
        </Routes>
      </BrowserRouter>
    </ReduxProvider>
  )
}

export default App;
