import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../auth/types';
import { AvailableSliceState } from './types';
import { requestGetAvailableDays } from './asyncActions';


const initialState: AvailableSliceState = {
    message: '',
    status: Status.CALM as Status,
    times: [],
}

const availableSlice = createSlice({
    name: 'available_times',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get Availables Times
        builder.addCase(requestGetAvailableDays.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetAvailableDays.fulfilled, (state, action) => {
            if (action.payload) {
                state.status = Status.SUCCESS;
                state.times = action.payload;
            } else {
                state.times = [];
                state.status = Status.ERROR;
                state.message = 'Произошла ошибка при получении допустимого времени';
            }
        })
        builder.addCase(requestGetAvailableDays.rejected, (state, action) => {
            state.status = Status.ERROR;
            state.times = [];
        })
    }
})

export const {} = availableSlice.actions;

export default availableSlice.reducer;