import $api from '../http/index';


export default class CertificateService {
    static async getCertificates() {
        const res = await $api.get('client/v1/documents/')
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async getCertificate(certId: number | string) {
        const res = await $api.get(`client/v1/documents/${certId}/`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res; 
    }
}