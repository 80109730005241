import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";


const Spinner = () => {
    return (
        <Spin
            style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}
            indicator={<LoadingOutlined style={{fontSize: 40, color: '#8DE719'}} spin />}
        />
    )
}

export default Spinner;