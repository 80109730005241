import { createSlice, PayloadAction } from '@reduxjs/toolkit';


import { Status } from '../my-services/types';
import { requestPackages } from './asyncActions';
import { Package, PackagesSliceState, getPackageByAliasType } from './types';


const initialState: PackagesSliceState = {
    message: '',
    status: Status.CALM as Status,
    subscriptionPackages: [],
    singlePackages: [],
    activePackage: null,
}

const packagesSlice = createSlice({
    name: 'packages',
    initialState,
    reducers: {
        setPackageByAlias: (state, action: PayloadAction<getPackageByAliasType>): void => {
            const alias = action.payload.alias;
            const type = action.payload.type;

            const packages = type === '0' ? state.singlePackages : state.subscriptionPackages;
            
            if (packages && packages.length > 0) {
                const idx = packages.findIndex((el: any) => el.alias === alias);
                if (idx !== -1) {
                    state.activePackage = packages[idx];
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestPackages.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestPackages.fulfilled, (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {
                const packagesSingle:   any[] = [];
                const packagesSubscription: any[] = [];
                
                // Filter New Packages
                const data = action.payload.filter(pkg => !pkg.alias.startsWith('RB'));
    
                // Filter by alias
                data.forEach((p: Package) => {
                    if (p.alias.includes('sub_vi')) {
                        packagesSubscription.push(p);
                    } else {
                        packagesSingle.push(p);
                    }
                })

                state.singlePackages       = packagesSingle;
                state.subscriptionPackages = packagesSubscription;
            }
            state.status = Status.SUCCESS;
        })
        builder.addCase(requestPackages.rejected, (state, action) => {
            state.status = Status.ERROR;
        })
    },
})

export const { setPackageByAlias } = packagesSlice.actions;

export default packagesSlice.reducer;