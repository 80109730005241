import './ChooseDate.css';
import style from './ChooseDate.module.scss';

// Date
import moment from 'moment';

// React
import { useEffect, useState } from 'react';

// Router
import { useNavigate, useParams } from 'react-router-dom';

// Ant Design
import { message as antdMessage } from 'antd';
import { DatePicker, DatePickerProps, Modal } from 'antd';

// Telegram
import { MainButton, BackButton } from '@vkruglikov/react-telegram-web-app';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { selectAvailableTimes } from '../../redux/availableDay/selectors';
import { requestGetAvailableDays } from '../../redux/availableDay/asyncActions';
import { selectMyServices } from '../../redux/my-services/selectors';
import { requestActiveService } from '../../redux/my-services/asyncActions';
import { setMessage, setRequestId } from '../../redux/my-services/slice';



const ChooseDate = () => {
    const navigate = useNavigate();
    let { serviceId } = useParams();

    const [activeIdx, setActiveIdx] = useState<any>(null);

    // Show Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Redux
    const dispatch = useAppDispatch();

    // Get Availables Times
    const { times } = useSelector(selectAvailableTimes);

    const onChange: DatePickerProps['onChange'] = (_, dateString: any) => {
        dispatch(requestGetAvailableDays(dateString));
    };

    // Modal
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        handleActivateService();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // Activate Service
    const { message, request_id } = useSelector(selectMyServices);
    const [comment, setComment] = useState('');

    const handleActivateService = () => {
        if (comment.length === 0) {
            messageApi.error('Пожалуйста опишите ваш вопрос', 2);
            return;
        }
        if (serviceId) {
            dispatch(requestActiveService({
                comment: comment,
                service_id: Number(serviceId),
            }))
            setIsModalOpen(false);
        }
    }

    useEffect(() => {
        if (message.length > 0) {
            if (request_id) {
                navigate(`/home/chat/${request_id}/active`);
            } else {
                messageApi.error(message, 2);
            }
        }

        return () => {
            dispatch(setMessage(''));
            dispatch(setRequestId(null));
        }
    }, [message, request_id, dispatch, messageApi, navigate])

    return (
        <div className={style.choose_date}>
            <BackButton onClick={() => navigate('/home/profile')} />
            {contextHolder}
            <h2>Выберите дату</h2>
            <DatePicker
                open={true}
                onChange={onChange}
                format="YYYY-MM-DD"
                style={{ visibility: "hidden", textAlign: 'center' }}
            />
            <div className={style.choose_date__day}>
                {times.length > 0
                    ? (
                        <div className={style.choose_date__day__times}>
                            {times.map((time, idx) => (
                                <button onClick={() => setActiveIdx(idx)} className={activeIdx === idx ? style.active : ''} key={idx}>
                                    {moment.unix(time.start).locale('ru').format('HH:mm')}
                                    -
                                    {moment.unix(time.end).locale('ru').format('HH:mm')}
                                </button>
                            ))}
                        </div>
                    )
                    : (
                        <div>
                            <p>Время отсутствует</p>
                        </div>
                    )}
            </div>
            <Modal
                title="Запись на консультацию"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={'Создать'}
                cancelText={'Отмена'}
            >
                <textarea
                    rows={5}
                    style={{width: '100%'}}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder='Помогите психологу подготовиться к консультации с вами, коротко опишите вашу проблему'
                />
            </Modal>
            {activeIdx && !isModalOpen && (
                <MainButton text='Подтвердить' color='#8DE719' onClick={showModal} />
            )}
        </div>
    )
}

export default ChooseDate;