import style from './Direction.module.scss';

// Router
import { Link } from 'react-router-dom';

// Ant Design
import { RightCircleOutlined } from '@ant-design/icons';


type Props = {
    title: string;
    link: string;
}

const Direction = ({ title, link }: Props) => {
    return (
        <Link to={link} className={style.direction}>
            <p>{title}</p>
            <RightCircleOutlined />
        </Link>
    )
}

export default Direction;