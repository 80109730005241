import style from './Menu.module.scss';


type Props = {
    activeBtn: number;
    setActiveBtn: React.Dispatch<React.SetStateAction<number>>;
    btnOneText: string;
    btnTwoText: string;
}


const Menu = ({ activeBtn, setActiveBtn, btnOneText, btnTwoText }: Props) => {
    return (
        <div className={style.menu}>
            <button
                onClick={() => setActiveBtn(0)}
                className={activeBtn === 0 ? style.menu__navigate__active : ''}
            >{btnOneText}</button>
            <button
                onClick={() => setActiveBtn(1)}
                className={activeBtn === 1 ? style.menu__navigate__active : ''}
            >{btnTwoText}</button>
        </div>
    )
}

export default Menu;