import style from './Pay.module.scss';

// Ant Design
import { Checkbox } from 'antd';
import type { CheckboxProps } from 'antd';
import { message as antdMessage } from 'antd';

// Telegram
import { BackButton, MainButton, useShowPopup, useWebApp, WebAppUser } from '@vkruglikov/react-telegram-web-app';

// Router
import { useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import { selectPackages } from '../../redux/packages/selectors';
import { useAppDispatch } from '../../redux/store';
import { useEffect, useRef, useState } from 'react';
import { CheckPaymentFormData, CreateUrlPaymentFormData } from '../../redux/payments/types';
import { requestCheckPayment, requestCreatePaymentUrl } from '../../redux/payments/asyncActions';
import { setPaymentStatusIsNull, setPaymentUrlIsNull } from '../../redux/payments/slice';
import { requestMyServices } from '../../redux/my-services/asyncActions';
import { selectPayments } from '../../redux/payments/selectors';
import { QuestionCircleOutlined } from '@ant-design/icons';


const checkEmailRegex = (email: string) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(email)) {
        return true;
    }
    else {
        return false;
    }
}


const Pay = () => {
    // Active Package
    const { activePackage } = useSelector(selectPackages);
    const [email, setEmail] = useState<string>('');

    const onChange: CheckboxProps['onChange'] = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    // Show Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Navigate
    const navigate = useNavigate();

    const navigateBack = () => {
        navigate(-1);
    }

    // Redux
    const dispatch = useAppDispatch();
    const intervalIdsRef = useRef<NodeJS.Timeout[]>([]);

    // Payment Handler
    const WebApp = useWebApp();
    const showPopup = useShowPopup();
    const { payment_url, statusPayment } = useSelector(selectPayments);

    const user: WebAppUser = WebApp.initDataUnsafe.user;

    const payment_handler = () => {
        if (activePackage) {
            if (email.length <= 0) {
                messageApi.error('Пожалуйста введите Email', 2);
                return;
            }
            if (!checkEmailRegex(email)) {
                messageApi.error('Пожалуйста введите Валидный Email', 2);
                return;
            }

            const formData: CreateUrlPaymentFormData = {
                alias: activePackage.alias,
                price: activePackage.price,
                telegram_id: user.id,
                description: activePackage.description,
                verbose_name: activePackage.verbose_name,
                email: email,
            }
            dispatch(requestCreatePaymentUrl(formData));
        }
    }

    const [isOpenInvoice, setOpenInvoice] = useState<boolean>(false);

    useEffect(() => {
        if (payment_url) {
            // clearAllIntervals();
            WebApp.openInvoice(payment_url);
            setOpenInvoice(true);

            WebApp.onEvent('invoiceClosed', (object: any) => {
                if (object.status === 'cancelled') {
                    messageApi.info('Вы отменили оплату', 2);
                    // clearAllIntervals();
                    dispatch(setPaymentUrlIsNull());
                    dispatch(setPaymentStatusIsNull());
                }
            });
        }
        return () => {
            clearAllIntervals();

            // dispatch(setPromocodeIsNull());
            dispatch(setPaymentUrlIsNull());
            dispatch(setPaymentStatusIsNull());
            dispatch(requestMyServices());
        }
    }, [payment_url, WebApp, dispatch])

    useEffect(() => {
        if (isOpenInvoice) {
            // Check Payment
            const formData: CheckPaymentFormData = {
                telegram_id: user.id,
            }

            if (intervalIdsRef.current.length === 0) {
                const intervalId = setInterval(() => {
                    dispatch(requestCheckPayment(formData));
                }, 5000);

                // Сохраняем идентификатор интервала в массиве
                intervalIdsRef.current.push(intervalId);
            }
        }
    }, [isOpenInvoice, dispatch])

    useEffect(() => {
        if (statusPayment) {
            clearAllIntervals();
            messageApi.success('Вы успешно оплатили услугу', 2);
            dispatch(requestMyServices());
        }
    }, [statusPayment, dispatch])

    const clearAllIntervals = () => {
        intervalIdsRef.current.forEach(intervalId => clearInterval(intervalId));
        intervalIdsRef.current = [];  // Очищаем массив после удаления всех интервалов
    };

    return (
        <div className={style.pay_page}>
            {contextHolder}
            <BackButton onClick={navigateBack} />
            <div className={style.pay_page__info}>
                <p className={style.pay_page__info__title}>Оплата «{activePackage?.verbose_name}»</p>
                <p className={style.pay_page__info__price}>{activePackage?.price} ₽</p>
            </div>
            <div className={style.email_title}>
                <p>Ваш Email</p>
                <QuestionCircleOutlined
                    onClick={() => messageApi.info('Ваш Email нужен, для того, чтобы отправить чек вам на почту', 2)}
                />
            </div>
            <form>
                <div className={style.pay_page__email_block}>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} />
                </div>
            </form>
            <div className={style.pay_page__agreement}>
                <Checkbox onChange={onChange} />
                <p>Соглашаюсь с <a href="#">Офертой</a> и <a href="#">Политикой конфиденциальности</a></p>
            </div>
            <MainButton color='#e6f2c5' text='Оплатить' onClick={payment_handler} />
        </div>
    )
}

export default Pay;