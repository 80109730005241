import style from './Home.module.scss';

// Images
import tst1 from '../../assets/img/home/tst1.png';
import tst2 from '../../assets/img/home/tst2.png';
import tst3 from '../../assets/img/home/tst3.png';
import tst4 from '../../assets/img/home/tst4.png';

// Components
import Test from '../../components/test/Test';
import Direction from '../../components/direction/Direction';


const Home = () => {
    return (
        <div className={style.home_page}>
            {/* <h3>Узнайте себя лучше</h3>

            <div className={style.home_page__tests}>
                <h4>Пройдите тест</h4>
                <div className={style.home_page__tests_items}>
                    <Test
                        title='Тест 1'
                        image_src={tst1}
                        text='ТЕСТ НА ТИП ЛИЧНОСТИ'
                    />
                    <Test
                        title='Тест 2'
                        image_src={tst2}
                        text='С ЧЕМ ОБРАТИТЬСЯ К ПСИХОЛОГУ'
                    />
                    <Test
                        title='Тест 3'
                        image_src={tst3}
                        text='КРИЗИС СРЕДНЕГО ВОЗРАСТА'
                    />
                    <Test
                        title='Тест 4'
                        image_src={tst4}
                        text='КАКОЙ ВАШ ЯЗЫК ЛЮБВИ'
                    />
                </div>
            </div> */}
            <div className={style.our_directions}>
                <h4>Наши направления</h4>
                <div className={style.our_directions__items}>
                    <Direction link='/home/package/1' title='Управления эмоциями 🧘🏼' />
                    <Direction link='/home/package/2' title='Депрессия и выход из неё 😔' />
                    <Direction link='/home/package/3' title='Родители и дети 👨‍👩‍👧' />
                    <Direction link='/home/package/4' title='Подростковые трудности 🙎‍♀️🙍' />
                    <Direction link='/home/package/5' title='Личная/Семейная жизнь 👫' />
                    <Direction link='/home/package/6' title='Сексология 👩‍❤️‍👨' />
                    <Direction link='/home/package/7' title='Beauty-психология 💠' />
                    <Direction link='/home/package/8' title='РПП 🤭' />
                    <Direction link='/home/package/9' title='Зависимость 😵' />
                    <Direction link='/home/package/10' title='Бизнес/Финансы/Карьера 💰' />
                    <Direction link='/home/package/11' title='Жестокое обращение в семье 😣' />
                </div>
            </div>
        </div>
    )
}

export default Home;