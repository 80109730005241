import style from './Message.module.scss';

import { DownloadOutlined } from '@ant-design/icons';

// Date
import Moment from 'moment';

// Ant Design
import { Empty, Image, Space } from 'antd';
import { ChatData } from '../../../redux/chats/types';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';


type PropsSendMsg = {
    text: string;
    date: string;
}

type PropsMessageFile = {
    filename?: string;
    link: string;
    date: string;
    WebApp: any;
}


export const MessageSend = ({ text, date }: PropsSendMsg) => {
    return (
        <div className={style.msg_send}>
            <p dangerouslySetInnerHTML={{__html: text}} className={style.msg_send__text}></p>
            <p className={style.msg_send__date}>{date}</p>
        </div>
    )
}


export const MessageReceived = ({ text, date }: PropsSendMsg) => {
    return (
        <div className={style.msg_received}>
            <p dangerouslySetInnerHTML={{__html: text}} className={style.msg_received__text}></p>
            <p className={style.msg_received__date}>{date}</p>
        </div>
    )
}


export const MessageFileSend = ({ link, filename, date, WebApp }: PropsMessageFile) => {
    const onDownload = (src: string) => {
        WebApp.openLink(src);
    };
    return (
        <div className={style.chat__send_message_file}>
            <p>{filename}</p>
            <button onClick={() => onDownload(link)}>Скачать Документ</button>
            <p>{date}</p>
        </div>
    )
}


export const MessageFileReceived = ({ link, filename, date, WebApp }: PropsMessageFile) => {
    const onDownload = (src: string) => {
        WebApp.openLink(src);
    };
    return (
        <div className={style.chat__receiver_message_file}>
            <p>{filename}</p>
            <button onClick={() => onDownload(link)}>Скачать Документ</button>
            <p>{date}</p>
        </div> 
    )
}


export const MessageImageSend = ({ link, date, WebApp }: PropsMessageFile) => {
    const onDownload = (src: string) => {
        WebApp.openLink(src);
    };

    return (
        <div className={style.chat__send_message_img}>
            <Image
                width={'100%'}
                src={link}
                preview={{
                    toolbarRender: (
                        _,
                    ) => (
                        <Space size={12} className="toolbar-wrapper">
                            <DownloadOutlined style={{ fontSize: 40 }} onClick={() => onDownload(link)} />
                        </Space>
                    ),
                }}
            />
            <p>{date}</p>
        </div>
    )
}


export const MessageImageReceived = ({ link, date }: PropsMessageFile) => {
    const onDownload = (src: string) => {
        // WebApp.openLink(src);
    };

    return (
        <div className={style.chat__receiver_message_img}>
            <Image
                width={'100%'}
                src={link}
                preview={{
                    toolbarRender: (
                        _,
                    ) => (
                        <Space size={12} className="toolbar-wrapper">
                            <DownloadOutlined style={{ fontSize: 40 }} onClick={() => onDownload(link)} />
                        </Space>
                    ),
                }}
            />
            <p>{date}</p>
        </div>
    )
}

type Props = {
    activeChat: ChatData | null;
}

export const Messages = ({ activeChat }: Props) => {
    // Telegram
    const WebApp = useWebApp();

    return (
        activeChat && activeChat.messages.length > 0
        ? (
            <>
                {activeChat.messages.map((msg, idx) => (
                    msg.type === 'text'
                        ? (
                            msg.kind === 'customer'
                                ? <MessageSend key={msg.id} text={msg.value} date={Moment(msg.dt_created).format('HH:mm')} />
                                : <MessageReceived key={msg.id} text={msg.value} date={Moment(msg.dt_created).format('HH:mm')} />
                        )
                        : msg.type === 'image'
                            ? (
                                msg.kind === 'customer'
                                    ? <MessageImageSend
                                        WebApp={WebApp}
                                        key={msg.id}
                                        link={msg.link}
                                        date={Moment(msg.dt_created).format('HH:mm')}
                                        />
                                    : <MessageImageReceived
                                        WebApp={WebApp}
                                        key={msg.id}
                                        link={msg.link}
                                        date={Moment(msg.dt_created).format('HH:mm')}
                                        />
                            )
                            : msg.type === 'file' && (
                                msg.kind === 'customer'
                                    ? (
                                        <MessageFileSend
                                            WebApp={WebApp}
                                            key={msg.id}
                                            filename={msg.value}
                                            link={msg.link}
                                            date={Moment(msg.dt_created).format('HH:mm')}
                                        />
                                    )
                                    : (
                                        <MessageFileReceived
                                            WebApp={WebApp}
                                            key={msg.id}
                                            filename={msg.value}
                                            link={msg.link}
                                            date={Moment(msg.dt_created).format('HH:mm')}
                                        />
                                    )
                            )
                ))}
            </>
        )
        : (
            <Empty
                className={style.chat__empty}
                description={
                    <span>
                        Нет сообщений
                    </span>
                }
            />
        )
    )
}