import style from './Package.module.scss';

// React
import { useEffect } from 'react';

// Router
import { useNavigate, useParams } from 'react-router-dom';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// Components
import Spinner from '../../components/spinner/Spinner';

// Redux
import { useSelector } from 'react-redux';
import { Status } from '../../redux/auth/types';
import { useAppDispatch } from '../../redux/store';
import { selectPackages } from '../../redux/packages/selectors';
import { requestPackages } from '../../redux/packages/asyncActions';
import { setPackageByAlias } from '../../redux/packages/slice';
import { getPackageByAliasType } from '../../redux/packages/types';


interface packageType {
    id: number;
    title: string;
    description: string;
}

const packageInfo: packageType[] = [
    {
        id: 1,
        title: 'Управления эмоциями 🧘🏼',
        description: 'Самооценка, уверенность и любовь к себе, личные границы, умение слышать и выслушивать себя'
    },
    {
        id: 2,
        title: 'Депрессия и выход из неё 😔',
        description: 'Профилактика, диагностика состояния и поддержка в случае дисбаланса',
    },
    {
        id: 3,
        title: 'Родители и дети 👨‍👩‍👧',
        description: 'Сепарация, прощение, принятие ответственности, решение накопившихся родительских вопросов',
    },
    {
        id: 4,
        title: 'Подростковые трудности 🙎‍♀️🙍',
        description: 'Самооценка, буллинг, профессиональная ориентация',
    },
    {
        id: 5,
        title: 'Личная/Семейная жизнь 👫',
        description: 'Помощь при расставании, работа со страхом одиночества и новых отношений, доверие к себе и партнеру',
    },
    {
        id: 6,
        title: 'Сексология 👩‍❤️‍👨',
        description: 'Отказ от секса, страх близости, низкое и высокое либидо, беспорядочная половая жизнь',
    },
    {
        id: 7,
        title: 'Beauty-психология 💠',
        description: 'Работа с женским состоянием, внешним видом и личной жизнью, кожные высыпания, вес, отеки, цистит и другие женские реагирования',
    },
    {
        id: 8,
        title: 'РПП 🤭',
        description: 'Переедание, лишний вес, анорексия, булимия, эмоциональное голодание или заедание',
    },
    {
        id: 9,
        title: 'Зависимость 😵',
        description: 'Игромания, шопоголизм, трудоголизм',
    },
    {
        id: 10,
        title: 'Бизнес/Финансы/Карьера 💰',
        description: 'Формирование мотивации и достижение целей без выгорания, определение своего финансового пути, выход на новый уровень дохода, профориентация, мотивация',
    },
    {
        id: 11,
        title: 'Жестокое обращение в семье 😣',
        description: 'Жестокое обращение в семье',
    }
]


const Package = () => {
    let { packageId } = useParams();

    let filteredPageTitle = packageInfo.filter(item => item.id === Number(packageId));

    // Redux
    const dispatch = useAppDispatch();
    const { status, subscriptionPackages, singlePackages } = useSelector(selectPackages);

    // Get Information Packages
    useEffect(() => {
        if (status === Status.CALM) {
            dispatch(requestPackages());
        }
    }, [dispatch, status])

    // Navigate
    const navigate = useNavigate();

    const navigateBack = () => {
        navigate(-1);
    }

    // Choose Active Package
    const chooseActivePackage = (alias: string, type: string) => {
        const formDataActivePackage: getPackageByAliasType = {
            alias: alias,
            type: type,
        }
        dispatch(setPackageByAlias(formDataActivePackage));

        // Redirect To Pay Page
        navigate('/home/payment-page');
    }

    return (
        <div className={style.package}>
            <BackButton onClick={navigateBack} />
            {filteredPageTitle.length > 0
                ? (
                    <>
                        <div className={style.package__info}>
                            <h2>{filteredPageTitle[0].title}</h2>
                            <p>{filteredPageTitle[0].description}</p>
                        </div>

                        <div className={style.package__sessions}>
                            <h2>Варианты сессий</h2>

                            <div className={style.package__sessions_sessions}>
                                {singlePackages.map((sub, idx) => (
                                    <div key={idx} className={style.package__sessions__session} onClick={() => chooseActivePackage(sub.alias, '0')}>
                                        <div className={style.package__session_left}>
                                            <h3>{sub.verbose_name}</h3>
                                            <div>
                                                <p>1 сеанс - {sub.duration} мин</p>
                                                <p>Срок действия после оплаты услуги: {sub.period.value} дней</p>
                                            </div>
                                        </div>
                                        <div className={style.package__session_right}>
                                            <p>{sub.price} ₽</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        
                        <div className={style.package__subscriptions}>
                            <h2>Подписка</h2>

                            <div className={style.package__subscriptions_subscriptions}>
                                {subscriptionPackages.map((sub, idx) => (
                                     <div key={idx} className={style.package__subscriptions_subscription} onClick={() => chooseActivePackage(sub.alias, '1')}>
                                        <div className={style.package__subscription_left}>
                                            <h3>{sub.verbose_name}</h3>
                                            <div>
                                                <p>Срок действия: {sub.period.value} дней ({sub.count_sessions} консультаций)</p>
                                            </div>
                                        </div>
                                        <div className={style.package__subscription_right}>
                                            <p>{sub.price} ₽</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )
                : (
                    <Spinner />
                )}
        </div>
    )
}

export default Package;