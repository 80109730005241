import './Upload.css';

import { useState } from 'react';

// Ant Design
import { Image, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { GetProp, UploadFile, UploadProps } from 'antd';

// Redux
import { useAppDispatch } from '../../../redux/store';
import { removeFileById } from '../../../redux/chats/slice';


type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

type Props = {
    fileList: UploadFile[];
    setFileList: (fileList: UploadFile[]) => void;
    props: UploadProps<any>;
}

const UploadImage = ({ fileList, setFileList, props }: Props) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    // Redux
    const dispatch = useAppDispatch();

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        // Get removed file
        const removedFile = fileList.find(file => !newFileList.map(newFile => newFile.uid).includes(file.uid));
        if (removedFile) {
            // Get removed file index
            const removedFileIndex = fileList.findIndex(file => !newFileList.map(newFile => newFile.uid).includes(file.uid));
            if (removedFileIndex !== -1) {
                dispatch(removeFileById(removedFileIndex))
            }
        }
        setFileList(newFileList);
    }

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );
    return (
        <>
            <Upload
                {...props}
                listType="picture-card"
                fileList={fileList}
                className='upload_image'
                onPreview={handlePreview}
                onChange={handleChange}
                showUploadList={{
                    showPreviewIcon: false,
                }}
            >
                {fileList.length >= 4 ? null : uploadButton}
            </Upload>
            {previewImage && (
                <Image
                    height={65}
                    width={65}
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                        visible: previewOpen,
                        height: 65,
                        width: 65,
                        destroyOnClose: false,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </>
    )
}

export default UploadImage;