import style from './Support.module.scss';

// React
import { Link, useNavigate } from 'react-router-dom';

// Ant Design
import { RightCircleOutlined } from '@ant-design/icons';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// Images
import emailImg from '../../assets/img/support/help_mail.png';
import phoneImg from '../../assets/img/support/help_phone.png';


const Support = () => {
    // Navigate
    const navigate = useNavigate();

    const navigateBack = () => {
        navigate(-1);
    }

    return (
        <div className={style.support}>
            <BackButton onClick={navigateBack} />
            <h2>Остались вопросы?</h2>
            <Link className={style.support__questions} to={''}>
                <div className={style.support__questions__text}>
                    <p className={style.support__questions__title}>Часто задаваемые вопросы</p>
                    <p className={style.support__questions__desc}>Найдите ответ на свой вопрос</p>
                </div>
                <RightCircleOutlined />
            </Link>
            <div className={style.support__support_service}>
                <h3>Служба Поддержки</h3>
                <p className={style.support__support_service__description}>Свяжитесь с нами любым удобным способом</p>
                <div className={style.support__support_service__contact}>
                    <div>
                        <img src={emailImg} alt="email-support" />
                        <p>info@vipsy.online</p>
                    </div>
                    <div>
                        <img src={phoneImg} alt="phone-support" />
                        <p>8 800 600 99 93</p>
                    </div>
                </div>
            </div>
            <div className={style.support__support_description}>
                <p>«ViPsy» - это не скорая психологическая помощь. Если у вас серьезные, угрожающие вашей жизни проблемы, которые требуют немедленного решения, вам лучше обратиться в какую-либо из <a href="">этих организаций.</a></p>
            </div>
        </div>
    )
}

export default Support;