import style from './MySessions.module.scss';

// React
import { useEffect, useState } from 'react';

// Date
import Moment from 'moment';

// Images
import videoImg from '../../assets/img/chat/video.svg';
import Session from '../../components/session/Session';

// Components
import Menu from '../../components/menu/Menu';

// Redux
import { useSelector } from 'react-redux';
import { Status } from '../../redux/chats/types';
import { useAppDispatch } from '../../redux/store';
import { selectMyChats } from '../../redux/chats/selectors';
import { requestGetChats } from '../../redux/chats/asyncActions';


const MySessions = () => {
    const [activeBtn, setActiveBtn] = useState<number>(0);

    // Redux
    const dispatch = useAppDispatch();
    const { status, chats, closedChats } = useSelector(selectMyChats);

    // Get My Chats
    useEffect(() => {
        if (!chats.length && status === Status.CALM) {
            dispatch(requestGetChats());
        }
    }, [chats, activeBtn, status, dispatch])

    return (
        <div className={style.my_sessions}>
            <h3>Общение со специалистом</h3>

            <Menu
                btnTwoText='Архив'
                btnOneText='Текущие'
                activeBtn={activeBtn}
                setActiveBtn={setActiveBtn}
            />

            <div className={style.my_sessions__items}>
                {activeBtn === 0
                    ? (
                        chats.length > 0
                            ? chats.map((chat, idx) => (
                                <Session
                                    key={idx}
                                    type='active'
                                    image={videoImg}
                                    chatId={chat.request_id}
                                    title={chat.package_name}
                                    date={Moment(chat.dt_created).format('DD.MM.YYYY в HH:mm')}
                                />
                            ))
                            : <p className={style.my_sessions__items__text}>У вас нет {activeBtn === 0 ? 'текущих' : 'архивных'} чатов</p>
                    )
                    : (
                        closedChats.length > 0
                            ? closedChats.map((chat, idx) => (
                                <Session
                                    key={idx}
                                    type='archive'
                                    image={videoImg}
                                    chatId={chat.request_id}
                                    title={chat.package_name}
                                    date={Moment(chat.dt_created).format('DD.MM.YYYY в HH:mm')}
                                />
                            ))
                            : <p className={style.my_sessions__items__text}>У вас нет {activeBtn === 0 ? 'текущих' : 'архивных'} чатов</p>
                    )}
            </div>
        </div>
    )
}

export default MySessions;