import style from './Session.module.scss';

// Router
import { useNavigate } from 'react-router-dom';

// Ant Design
import { MessageOutlined } from '@ant-design/icons';


type Props = {
    date: string;
    image: string;
    title: string;
    type: string;
    chatId: number;
}

const Session = ({ chatId, type, date, image, title }: Props) => {
    const navigate = useNavigate();

    const handleLinkTo = () => {
        navigate(`/home/chat/${chatId}/${type}`);
    }
    return (
        <div className={style.session}>
            <div className={style.session__item__block}>
                <div className={style.session__item_img}>
                    <img src={image} alt="session-img" />
                </div>
            </div>
            <div className={style.session__item__info}>
                <p className={style.date}>{date}</p>
                <p className={style.title}>{title}</p>
                <div className={style.session__item__info__btn}>
                    <button onClick={() => handleLinkTo()}>Написать в чат <MessageOutlined /></button>
                </div>
            </div>
        </div>
    )
}

export default Session;