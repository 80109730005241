import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../auth/types';
import { CertificatesSliceState } from './types';
import { requestGetCertificate, requestGetCertificates } from './asyncActions';


const initialState: CertificatesSliceState = {
    message: '',
    certificates: [],
    status: Status.CALM as Status,
    certificate: null,
}

const certificatesSlice = createSlice({
    name: 'certificates',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get Certificates
        builder.addCase(requestGetCertificates.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetCertificates.fulfilled, (state, action) => {
            if (action.payload) {
                state.status = Status.SUCCESS;
                state.certificates = action.payload;
            } else {
                state.status = Status.ERROR;
                state.message = 'Произошла ошибка при получении сертификатов';
            }
        })
        builder.addCase(requestGetCertificates.rejected, (state, action) => {
            state.status = Status.ERROR;
        })
        // Get Certificate
        builder.addCase(requestGetCertificate.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetCertificate.fulfilled, (state, action) => {
            if (action.payload) {
                state.status = Status.SUCCESS;
                state.certificate = action.payload.certificate;
            } else {
                state.status = Status.ERROR;
                state.message = 'Произошла ошибка при получении сертификата';
            }
        })
        builder.addCase(requestGetCertificate.rejected, (state, action) => {
            state.status = Status.ERROR;
        })
    }
})

export const {} = certificatesSlice.actions;

export default certificatesSlice.reducer;