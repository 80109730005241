import style from './Documents.module.scss';

import { useEffect, useState } from 'react';

// Ant Design
import { DownloadOutlined, RightOutlined } from '@ant-design/icons';

// Routers
import { useNavigate } from 'react-router-dom';

// Telegram
import { BackButton, useWebApp } from '@vkruglikov/react-telegram-web-app';

// Components
import Menu from '../../components/menu/Menu';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { Status } from '../../redux/my-services/types';
import { selectMyCertificate } from '../../redux/certificates/selectors';
import { requestGetCertificate, requestGetCertificates } from '../../redux/certificates/asyncActions';
import Spinner from '../../components/spinner/Spinner';


const Documents = () => {
    const [activeBtn, setActiveBtn] = useState<number>(0);

    // Telegram
    const WebApp = useWebApp();

    const onDownload = (src: string) => {
        WebApp.openLink(src);
    };

    // Navigate
    const navigate = useNavigate();

    const navigateBack = () => {
        navigate(-1);
    }

    // Get Certificates
    const dispatch = useAppDispatch();
    const { status, certificates, certificate } = useSelector(selectMyCertificate);
    useEffect(() => {
        if (!certificates.length && status === Status.CALM) {
            dispatch(requestGetCertificates())
        }
    }, [certificates, status, dispatch])

    // Get Certificate
    const DownloadCertificate = (ImageBase64: string) => {
        var a = document.createElement("a"); //Create <a>
        a.href = "data:application/pdf;base64," + ImageBase64; //Image Base64 Goes here
        a.download = "certificate.pdf"; //File name Here
        a.click();
    }

    const getCertificate = (certId: string) => {
        dispatch(requestGetCertificate(certId));
    }

    useEffect(() => {
        if (certificate) {
            DownloadCertificate(certificate)
        }
    }, [certificate])

    return (
        <div className={style.documents}>
            <BackButton onClick={navigateBack} />
            <Menu
                btnTwoText='Сертификаты'
                btnOneText='Общие'
                activeBtn={activeBtn}
                setActiveBtn={setActiveBtn}
            />

            {activeBtn === 0
                ? (
                    <div className={style.documents__general}>
                        <a href="">
                            <p>Правила акции. Условия использования промокодов</p>
                            <RightOutlined />
                        </a>
                        <a href="">
                            <p>Соглашение с пользователем</p>
                            <RightOutlined />
                        </a>
                        <a href="">
                            <p>Бланк согласия родителя на сессию ребенка с психологом</p>
                            <RightOutlined />
                        </a>
                        <a href="">
                            <p>Форма возврата</p>
                            <RightOutlined />
                        </a>
                        <a href="">
                            <p>Политика конфиденциальности</p>
                            <RightOutlined />
                        </a>
                    </div>
                )
                : (
                    status === Status.LOADING
                        ? <Spinner />
                        : (
                            <div className={style.documents__certificates}>
                                {certificates.length > 0
                                    ? (
                                        certificates.map((cert, idx) => (
                                            <a onClick={() => getCertificate(cert.certificate_number)} key={idx}>
                                                <p>{cert.package_verbose_name}</p>
                                                <DownloadOutlined />
                                            </a>
                                        ))
                                    )
                                    : <p>У вас нет сертификатов</p>}
                            </div>
                        )
                )}
        </div>
    )
}

export default Documents;