import style from './Chat.module.scss';

// React
import { useEffect, useState } from 'react';

// Date
import moment from 'moment';

// Router
import { useNavigate, useParams } from 'react-router-dom';

// Ant Design
import { message as antdMessage, Upload, UploadFile, UploadProps } from 'antd';
import { AudioOutlined, BorderOutlined, PaperClipOutlined, SendOutlined } from '@ant-design/icons';

// Audio Recorder
import { useAudioRecorder } from 'react-audio-voice-recorder';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// Components
import Spinner from '../../components/spinner/Spinner';
import { Messages } from '../../components/chat/message/Message';
import AudioRecord from '../../components/chat/AudioRecord/AudioRecord';

// Redux
import { useSelector } from 'react-redux';
import { AddNewImageToState, Status } from '../../redux/chats/types';
import { useAppDispatch } from '../../redux/store';
import { selectMyChats } from '../../redux/chats/selectors';
import { addNewImageMessage, addNewTextMessage, clearActiveChat, clearFileIds } from '../../redux/chats/slice';
import { requestMyServices } from '../../redux/my-services/asyncActions';
import { requestCreateMessage, requestGetChats, requestGetMessages, requestUploadFile } from '../../redux/chats/asyncActions';
import { getFormatFile } from '../../utils/getFormatFile';
import UploadImage from '../../components/chat/upload/Upload';


const Chat = () => {
    let { chatId, type } = useParams();

    const [message, setMessage] = useState<string>('');
    const [isRecord, setIsRecord] = useState<boolean>(false);

    // Get Chats
    const dispatch = useAppDispatch();
    const { status, activeChat, file_ids } = useSelector(selectMyChats);

    useEffect(() => {
        let intervalId: any = null;

        if (chatId && (status === Status.CALM || status !== Status.ERROR)) {
            intervalId = setInterval(() => {
                dispatch(requestGetMessages(chatId))
            }, 3000)
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        }
    })

    useEffect(() => {
        return () => {
            dispatch(clearFileIds());
            dispatch(clearActiveChat());
            dispatch(requestGetChats());
            dispatch(requestMyServices());
        }
    }, [])

    function addNewImage(formData: AddNewImageToState) {
        dispatch(addNewImageMessage(formData));

        clearHandle();

        dispatch(requestCreateMessage({
            chat_id: Number(chatId),
            content: message,
            file_ids: file_ids,
        }))
        setMessage('');
        setFileList([]);
        dispatch(clearFileIds());
    }

    function addNewMessageText() {
        if (message.length > 0) {
            dispatch(addNewTextMessage(message));
        }

        // Add Files
        setTimeout(() => {
            fileList.forEach(async (file) => {
                const formData: AddNewImageToState = {
                    type: getFormatFile(file.name),
                    link: file.url,
                    value: file.name,
                }
                dispatch(addNewImageMessage(formData));
            })
        }, 500)

        if (chatId && activeChat) {
            dispatch(requestCreateMessage({
                chat_id: Number(chatId),
                content: message,
                file_ids: file_ids,
            }))
            setMessage('');
            setFileList([]);
            dispatch(clearFileIds());
        }
    }


    // Create Message
    const handleCreateMessageVoice = async (e: any) => {
        const formData: AddNewImageToState = {
            type: 'file',
            link: recordBlobLink,
            value: voiceFileName,
        }

        if (status === Status.SUCCESS) {
            addNewImage(formData);
        } else {
            setTimeout(() => {
                addNewImage(formData);
            }, 2000);
        }
    }

    const handleCreateMessage = (e: any) => {
        e.preventDefault();

        if (status === Status.SUCCESS) {
            addNewMessageText();
        } else {
            setTimeout(() => {
                addNewMessageText();
            }, 2000)
        }
    }

    // Upload Image
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    async function getBase64(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = reject
        })
    }

    const props: UploadProps = {
        beforeUpload: async (file: any) => {
            const res: any = await getBase64(file);
            file.url = res;
            setFileList([...fileList, file]);

            dispatch(requestUploadFile({
                file: res,
                file_name: file.name,
            }))
            return false
        },
    };

    // Show Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Audio Record
    const [isRunning, setIsRunning] = useState<boolean>(false);
    const [elapsedTime, setElapsedTime] = useState<number>(0);
    const [recordBlobLink, setRecordBlobLink] = useState<any>(null);
    const [isStop, setIsStop] = useState<boolean>(false);
    const [voiceFileName, setVoiceFileName] = useState<string>('');

    const clearHandle = () => {
        setIsRunning(false)
        setRecordBlobLink(null)
        setElapsedTime(0)
        setIsRecord(false);
        setIsStop(false);
        dispatch(clearFileIds());
    }

    const {
        startRecording,
        stopRecording,
        recordingBlob,
        isRecording,
        recordingTime,
    } = useAudioRecorder({}, (err) => {
        messageApi.info(`Вы не дали приложению права на запись через микрофон.`, 2);
        clearHandle();
    });

    const handleClickVoice = () => {
        setElapsedTime(0);
        setIsRunning(true);
        setIsStop(false);
        setIsRecord(true);

        startRecording();
    }

    const handleClickVoiceStop = () => {
        stopRecording();
        setIsStop(true);
    }

    const uploadVoiceFile = async (recordingBlob: Blob) => {
        const randomNum = Math.floor(Math.random() * 3000000);
        const voiceFileName = `${moment().format("dddd, MMMM Do YYYY, h:mm:ss")}_${randomNum}.webm`;
        const voiceFile = new File([recordingBlob], voiceFileName, { type: "audio/webm" });
        const res: any = await getBase64(voiceFile);

        // Setup Info Voice File
        setVoiceFileName(voiceFileName);

        dispatch(requestUploadFile({
            file: res,
            file_name: voiceFile.name,
        }))
    }

    useEffect(() => {
        if (!recordingBlob) return;
        const url = URL.createObjectURL(recordingBlob);
        if (url) {
            uploadVoiceFile(recordingBlob);
        }
        setRecordBlobLink(url);
    }, [recordingBlob])

    // Navigate
    const navigate = useNavigate();

    const navigateBack = () => {
        navigate('/home/my-sessions');
    }

    return (
        <div className={style.chat}>
            <BackButton onClick={navigateBack} />
            {contextHolder}
            {!activeChat
                ? <Spinner />
                : (
                    <>
                        <div className={style.chat_messages}>
                            <Messages activeChat={activeChat} />
                        </div>
                        {type === 'active' && (
                            <div className={style.chat__control_block}>
                                <div className={isRecord ? style.record_handler : style.input_handler}>
                                    {isRecord
                                        ? (
                                            <AudioRecord
                                                isRunning={isRunning}
                                                elapsedTime={elapsedTime}
                                                recordBlobLink={recordBlobLink}
                                                setElapsedTime={setElapsedTime}
                                                clearHandle={clearHandle}
                                                recordingTime={recordingTime}
                                            />
                                        )
                                        : (
                                            <div className={style.message_block}>
                                                <Upload {...props} showUploadList={false}>
                                                    <PaperClipOutlined />
                                                </Upload>
                                                <input
                                                    type="text"
                                                    value={message}
                                                    placeholder='Сообщение...'
                                                    onChange={(e) => setMessage(e.target.value)}
                                                />
                                            </div>
                                        )}

                                    {message.length > 0 || fileList.length > 0
                                        ? <SendOutlined onClick={handleCreateMessage} className={style.send_icon} />
                                        : isRecord
                                            ? !isStop
                                                ? <BorderOutlined onClick={handleClickVoiceStop} />
                                                : <SendOutlined onClick={handleCreateMessageVoice} className={style.send_icon} />
                                            : <AudioOutlined onClick={handleClickVoice} />
                                    }
                                </div>
                                {fileList.length > 0 && (
                                    <div className={style.uploaded_images}>
                                        <UploadImage fileList={fileList} setFileList={setFileList} props={props} />
                                    </div>)}
                            </div>
                        )}
                    </>
                )}
        </div>
    )
}

export default Chat;