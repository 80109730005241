import style from './Welcome.module.scss';

// React
import { useEffect, useState } from 'react';

// Router
import { useNavigate } from 'react-router-dom';

// Image
import welcomeLogo from '../../assets/img/welcome/logo.svg';

// Redux
import { useAppDispatch } from '../../redux/store';
import { Profile } from '../../redux/profile/types';
import { requestAuth } from '../../redux/auth/asyncActions';

// Telegram
import { useWebApp, WebAppUser, MainButton } from '@vkruglikov/react-telegram-web-app';


const Welcome = () => {
    const WebApp = useWebApp();

    const user: WebAppUser = WebApp.initDataUnsafe.user;
    const [isClearLocalStorage, setIsClearLocalStorage] = useState<boolean>(false);

    // Redux
    const dispatch = useAppDispatch();

    // Get Token
    if (!isClearLocalStorage) {
        localStorage.clear();
        setIsClearLocalStorage(true);
    }
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (!token) {
            // Request Get Token
            const formData: Profile = {
                'telegram_id': user.id ? `${user.id}` : null,
                'first_name': user.first_name ? user.first_name : null,
                'last_name': user.last_name ? user.last_name : null,
                'username': user.username ? user.username : null,
            }
            dispatch(requestAuth(formData));
        }
    }, [token, dispatch, user])

    // Navigate
    const navigate = useNavigate();


    // Handle Click Continue
    const handleClickContinue = () => {
        navigate('/home');
    }

    return (
        <div className={style.welcome}>
            <div className={style.welcome__page}>
                <div className={style.welcome__page__logo}>
                    <img src={welcomeLogo} alt="welcome-logo" />
                </div>

                <div className={style.welcome__page__title}>
                    <p>Добро пожаловать в наш сервис онлайн консультаций с психологом випси (vipsy)</p>
                </div>
                <div className={style.welcome__page__description}>
                    <p>Психологи нашего сервиса помогут вам найти выход из любой ситуации, научат вас обходиться с негативными эмоциями и дадут совет.</p>
                </div>
            </div>

            <MainButton text='Продолжить' color='#8DE719' onClick={handleClickContinue} />
        </div>
    )
}

export default Welcome;