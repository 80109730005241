import style from './Layouts.module.scss';

// Router
import { Link, Outlet, useLocation } from 'react-router-dom';

// Images
import logoImg from '../assets/img/home/logo.jpeg';

// Ant Design
import { HomeOutlined, UserOutlined, WechatOutlined } from '@ant-design/icons';

// Components
import Spinner from '../components/spinner/Spinner';


export interface pageTitleType {
    name: string;
    pathname: string;
}

const pageTitle: pageTitleType[] = [
    {
        name: '/home',
        pathname: 'Главная',
    },
    {
        name: '/home/my-sessions',
        pathname: 'Мои сессии',
    },
    {
        name: '/home/profile',
        pathname: 'Профиль',
    },
    {
        name: '/home/documents',
        pathname: 'Документы',
    },
    {
        name: '/home/support',
        pathname: 'Поддержка',
    },
    {
        name: '/home/package/',
        pathname: 'Главная',
    },
    {
        name: '/home/payment-page',
        pathname: 'Способ оплаты',
    },
    {
        name: '/home/chat/',
        pathname: 'Чат',
    }
]


export function MainLayout() {
    const { pathname } = useLocation();

    let filteredPageTitle = pageTitle.filter(item => item.name === pathname);

    if (filteredPageTitle.length === 0) {
        filteredPageTitle = pageTitle.filter(item => pathname.includes('chat'));
        if (filteredPageTitle.length === 0) {
            filteredPageTitle = pageTitle.filter(item => item.name === '/home');
        }
    }
    return (
        <div className={style.main_layout}>
            {filteredPageTitle.length > 0
                ? (
                    <>
                        <div className={style.main_layout__header}>
                            {!pathname.includes('documents') || !pathname.includes('support') && (
                                <img src={logoImg} alt="logo-img" />
                            )}
                            <p>{filteredPageTitle[0].pathname}</p>
                        </div>
                        <div className={style.main_layout__content} style={pathname.includes('chat') ? {padding: 0} : {}}>
                            <Outlet />
                        </div>
                        <div className={style.main_layout__navbar}>
                            <ul>
                                <li className={style.main_layout__navbar_active}>
                                    <Link to={'/home'}>
                                        <HomeOutlined />
                                        <p>Главная</p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/home/my-sessions'}>
                                        <WechatOutlined />
                                        <p>Мои сессии</p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/home/profile'}>
                                        <UserOutlined />
                                        <p>Профиль</p>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </>
                )
                : <Spinner />}
        </div>
    )
}
